import Icon from "components/icon/icon";
import React from "react";
import "./buttonLink.scss";

export interface ButtonLinkDataProps {
  label: string;
  url?: string;
  file?: {
    mediaItemUrl: string;
  };
}

interface Props extends ButtonLinkDataProps {}

const ButtonLink: React.FC<Props> = (props) => {
  return (
    <a
      href={
        !!props.url
          ? props.url
          : !!props.file
          ? props.file.mediaItemUrl
          : undefined
      }
      target="_blank"
      rel="norefferer"
      className="button-link"
    >
      <div className="button-link__icon">
        <Icon
          icon={!!props.url ? "link" : "file"}
          color="#fff"
          size={!!props.url ? "18" : "20"}
        />
      </div>
      <div className="button-link__text">
        <span className="button-link__label">{props.label}</span>
        <span className="button-link__category">
          {!!props.url ? "Link" : "PDF"}
        </span>
      </div>
    </a>
  );
};

export default ButtonLink;
