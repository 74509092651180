import EventTeaser, { EventProps } from "components/event/eventTeaser";
import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";
import { pageContextProps } from "utils/props.utils";
import "./events.template.scss";
import imagesLoaded from "imagesloaded";
import { handlePageLoaded } from "components/trans/transLink";
import classNames from "classnames";
import { useLocation } from "@reach/router";
import SEO from "components/seo/seo";

interface Props {
  pageContext: pageContextProps;
  data: {
    events: {
      edges: {
        node: EventProps;
      }[];
    };
    eventsPast: {
      edges: {
        node: EventProps;
      }[];
    };
  };
}

const EventsTemplate: React.FC<Props> = (props) => {
  const mainRef = useRef<HTMLDivElement>(null);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  useEffect(() => {
    const main = mainRef.current;
    !!main &&
      imagesLoaded(
        document.querySelectorAll("[data-preload]"),
        handlePageLoaded
      );
    setIsLoaded(true);
  }, []);

  const location = useLocation();
  const [showPast, setShowPast] = useState<boolean>(false);

  return (
    <div
      className={classNames("events-template", {
        "is-loading-complete": isLoaded,
      })}
      id={props.pageContext.id}
      ref={mainRef}
    >
      <SEO {...props.pageContext.seo} />
      <section className="section">
        <div className="section-header">
          <h5 className="section-header__title">{props.pageContext.title}</h5>
        </div>
        <div className="section-content"></div>
        <div className="events-template-grid">
          <div className="wrapper">
            <div className="grid-row">
              {props.data.events.edges.map((item, key) => (
                <div className="grid-cell" key={key}>
                  <EventTeaser
                    {...item.node}
                    key={key}
                    isActive={location.hash === `#${item.node.slug}`}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {!!props.data.eventsPast.edges.length && (
          <div className="events-template-loadmore">
          <div className="wrapper">
            <button className="link-text" onClick={() => setShowPast(true)}>
              Vergangene Termine
            </button>
          </div>
        </div>
        )}
        

        {!!showPast && (
          <div className="events-template-grid">
            <div className="wrapper">
              <div className="grid-row">
                {props.data.eventsPast.edges.map((item, key) => {
                  return (
                    <div className="grid-cell sm-12" key={key}>
                      <EventTeaser
                        {...item.node}
                        key={key}
                        isActive={location.hash === `#${item.node.slug}`}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </section>
    </div>
  );
};

export default EventsTemplate;

export const query = graphql`
  query ($id: String!, $language: String!, $dateNow: Date!) {
    page: wpPage(id: { eq: $id }) {
      id
      title
    }
    events: allWpEvent(
      sort: { fields: event___date, order: ASC }
      filter: { event: { date: { gte: $dateNow } } }
    ) {
      edges {
        node {
          ...EventBlock
        }
      }
    }
    eventsPast: allWpEvent(
      sort: { fields: event___date, order: DESC }
      filter: { event: { date: { lt: $dateNow } } }
      limit: 4
    ) {
      edges {
        node {
          ...EventBlock
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
