import ButtonLink, { ButtonLinkDataProps } from 'components/buttonLink/buttonLink';
import { TransLink } from 'components/trans/transLink';
import { graphql } from 'gatsby';
import React from 'react';
import './links.scss';

export interface LinksProps {
  headline?: string;
  links: ButtonLinkDataProps[];
  linkStyle?: string;
}

interface Props extends LinksProps {}

const Links: React.FC<Props> = (props) => {
  return (
    <div className="links">
      <div className="links__wrapper">
        {!!props.headline && <div className="links__title">{props.headline}</div>}
        <div className="links__row">
          {!!props.links &&
            props.links.map((item, key) => (
              <div className="links__item" key={key}>
                {props.linkStyle === 'button' ? (
                  <TransLink to={item.url ?? item.file?.mediaItemUrl ?? '#'} className="link-button">
                    {item.label}
                  </TransLink>
                ) : (
                  <ButtonLink {...item} key={key} />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Links;

export const query = graphql`
  fragment LinksBlock on WpAcfLinksblockBlock {
    AcfLinks {
      links {
        label
        url
        file {
          mediaItemUrl
        }
      }
      linkStyle
    }
  }
`;
