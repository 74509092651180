import { graphql } from "gatsby";
import React, { useEffect, useRef, useState } from "react";

import "./eventTeaser.scss";
import Img, { ImgProps } from "components/img/img";
import classNames from "classnames";
import Partners, { PartnerProps } from "blocks/partners/partners";
import Links, { LinksProps } from "blocks/links/links";
import { months } from "data/date.data";
import { useLocation } from "@reach/router";
import moment from "moment";
// import "moment/locale/de";

export interface EventProps {
  id: string;
  uri: string;
  slug: string;
  title: string;
  content: string;
  event: {
    tagline?: string;
    referent?: string;
    city: string;
    date: string;
    list: {
      label: string;
      value: string;
    }[];
    partners?: PartnerProps;
    links?: LinksProps;
  };
  featuredImage: {
    node: ImgProps;
  };
}

interface Props extends EventProps {
  isActive: boolean;
}

const EventTeaser: React.FC<Props> = (props) => {
  const [activeHeight, setActiveHeight] = useState<number>(0);
  const contentRef = useRef<HTMLDivElement>(null);

  const dateSplit = props.event.date.split("-");
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      const content = contentRef.current;
      !!content && setActiveHeight(content.clientHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [props.isActive]);

  return (
    <div
      className={classNames("event-teaser", { "is-active": props.isActive })}
    >
      <div className="event-teaser__top">
        {/* DATE */}
        <div className="event-teaser-date">
          <div className="event-teaser-date__location">{props.event.city}</div>
          <div className="event-teaser-date__day">{dateSplit[2]}</div>
          <div className="event-teaser-date__day is-mobile">
            {moment(props.event.date).locale("de").format("DD. MMMM")}
          </div>
          <div className="event-teaser-date__month">
            {moment(props.event.date).locale("de").format("MMMM")}
          </div>
          <div className="event-teaser-date__year">{dateSplit[0]}</div>
        </div>
        {/* TITLE */}
        <div className="event-teaser-title">
          {!!props.event.tagline && <div className="event-teaser-title__tagline">{props.event.tagline}</div>}
          <h4 className="event-teaser-title__headline">{props.title}</h4>
          {!!props.event.referent && <div className="event-teaser-title__referent">{props.event.referent}</div>}
          <div className="event-teaser-title__expand">
            <a href={`#${props.slug}`} target="_self" className="link-text">
              Mehr Infos
            </a>
          </div>
        </div>
        {/* IMAGE */}
        <div
          className={classNames("event-teaser-image", {
            "no-image": !props.featuredImage,
          })}
        >
          {!!props.featuredImage && <Img {...props.featuredImage.node} />}
        </div>
      </div>
      <div
        className="event-teaser__bottom"
        style={{ height: props.isActive ? `${activeHeight}px` : "0px" }}
      >
        <div className="event-teaser__content grid-row" ref={contentRef}>
          {/* CONTENT */}
          <div className="event-teaser__content-left grid-cell">
            <div dangerouslySetInnerHTML={{ __html: props.content }} />
            {/* LIST */}
            {!!props.event.list && (
              <div className="event-teaser__table">
                <table>
                  <tbody>
                    {props.event.list.map((item, key) => (
                      <tr key={key}>
                        <td>{item.label}</td>
                        <td>{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
          <div className="event-teaser__content-right grid-cell">
            {/* PARTNERS */}
            {!!props.event.partners && !!props.event.partners.logos && (
              <Partners {...props.event.partners} />
            )}
            {/* LINKS */}
            {!!props.event.links && !!props.event.links.links && (
              <Links {...props.event.links} />
            )}
          </div>
          <div className="event-teaser__content-close">
            <div className="grid-cell">
              <a
                className="link-text"
                href={`#${location.pathname.replaceAll("/", "")}`}
              >
                Schliessen
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventTeaser;

export const query = graphql`
  fragment EventBlock on WpEvent {
    id
    uri
    slug
    title
    content
    event {
      tagline
      referent
      city
      date(locale: "de")
      list {
        label
        value
      }
      links {
        headline
        links {
          label
          url
          file {
            mediaItemUrl
          }
        }
      }
      partners {
        logos {
          externalLink
          height
          logo {
            altText
            sourceUrl
            localFile {
              childImageSharp {
                gatsbyImageData(
                  quality: 80
                  transformOptions: { grayscale: true }
                )
              }
              publicURL
            }
          }
        }
        headline
      }
    }
    featuredImage {
      node {
        ...FeaturedImage
      }
    }
  }
`;
