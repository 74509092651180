import Img, { ImgProps } from "components/img/img";
import { graphql } from "gatsby";
import React, { useEffect, useState } from "react";
import "./partners.scss";

export interface PartnerProps {
  headline?: string;
  logos: {
    externalLink: string;
    height: number;
    logo: ImgProps;
  }[];
}

interface Props extends PartnerProps {}

const Partners: React.FC<Props> = (props) => {
  const [logosHeight, setLogosHeight] = useState<number>(0);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 980) {
        setLogosHeight(108);
      } else if (window.innerWidth > 740) {
        setLogosHeight(98);
      } else {
        setLogosHeight(92);
      }
    }

    handleResize();
    document.addEventListener('resize', handleResize);
    return () => document.removeEventListener('resize', handleResize);
  }, [])

  return (
    <div className="partners">
      {!!props.headline && (
        <div className="partners__title">{props.headline}</div>
      )}
      {/* ICONS */}
      <div className="partners-logos grid-row">
        {props.logos.map((item, key) => (
          <div className="partners-logos__item grid-cell" key={key} style={{height: `${logosHeight / 100 * item.height}px`}}>
            <a
              className="partners-logos__link"
              key={key}
              href={item.externalLink}
              target="_blank"
              rel="norefferer"
            >
              <Img {...item.logo} />
              {/* <img
                src={
                  item.logo.localFile.childImageSharp.gatsbyImageData.images
                    .fallback.src
                }
                alt={item.logo.altText}
              /> */}
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Partners;

export const query = graphql`
  fragment PartnerBlock on WpAcfPartnersBlock {
    AcfPartners {
      logos {
        externalLink
        height
        logo {
          altText
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                quality: 80
                transformOptions: { grayscale: true }
              )
            }
            publicURL
          }
        }
      }
      headline
    }
  }
`;
